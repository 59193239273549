import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"

import img1 from "../images/climatisation1.jpg"
import img2 from "../images/climatisation2.jpg"
import img3 from "../images/climatisation3.jpg"
import img4 from "../images/climatisation4.jpg"
import img5 from "../images/climatisation5.jpg"

const ClimatisationPage  = () => (
    <Layout>
        <SEO 
            page="climatisation-mions"
            title="Climatisation à Mions, dépannage et installation"
            description="Vous ne voulez plus subir les fortes chaleurs estivales dans votre habitat ? Installez une climatisation à Mions avec Air Water Sytem au 06 98 99 96 96."
            keywords={["Dépannage", "Entreprise", "Société", "Artisan", "Réparation","Entretien", "Maintenance", "Installation", "Energie thermique", "Réversible","Ventilation","Extérieur","Intérieur","Système", 
            "climatisation","pompe à chaleur","ventilation","climatiseur","clim","frigorifique","climatiseurs","pompes à chaleur","pompes","fluide","refroidissement","condensation","réversible","réfrigérant",
            "fluide frigorigène","thermique","températures","frigorigène","frigorifiques , système de climatisation","rafraîchissement","chaleur air","fluides","systèmes de climatisation","air chaud","pompe",
            "extérieure","rafraîchir","humidité","thermodynamique","unité extérieure","économies d énergie","réfrigération","climatisations","ambiant","chauffer","réversibles","installateur","chauffage et climatisation",
            "climatiser","qualité de l air","air ambiant","air conditionné","refroidir","évacuation","réversible","air extérieur","air frais","unité intérieure","énergétiques","chaleur","frigorigènes","puissance frigorifique",
            "dépannage","mural","énergétique","froid","climatiseur mobile","climatiseur réversible","fraîcheur","air intérieur","consommation","consommation énergétique","climatiseur fixe","installations","niveau sonore",
            "air froid","débit d air","température","thermiques"]}
        />
        <section className="section">
            <div className="container">
                <h1 className="title">Climatisation à Mions</h1>
                <h2 className="subtitle">Dépannage et installation de climatisation à Mions</h2>
            </div>
        </section>
        <section className="section">
            <div className="container">
                <h3 className="title is-4">Des experts de la climatisation</h3>
                <div className="content">
                    <div className="columns is-vcentered">
                        <div className="column">
                            <p> 
                                Vous ne voulez plus subir les fortes chaleurs estivales dans votre habitat ? Votre climatisation ne souffle plus d'air froid et fonctionne à un niveau sonore trop élevé ? Pour obtenir une réfrigération optimale, vous devez donc penser à faire réparer votre climatiseur actuel ou à le remplacer. 
                                Si vous vivez à Mions dans le Rhône ou dans une commune avoisinante comme Lyon, Valence ou Villeurbanne, faites appel à un installateur de l'entreprise Air Water System pour se charger des travaux. 
                            </p>
                        </div>
                        <div className="column">
                            <figure class="image">
                                <img alt="Climatisation Mions" src={img1}></img>
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="section">
            <div className="container">
                <h3 className="title is-4">Quelles sont nos installation de systèmes de climatisation à Mions ?</h3>
                <p className="content">
                    Pour une pose d'appareils de climatisation effectuée dans des conditions optimales, vous pouvez confier votre projet à un spécialiste de la société Air Water System. 
                    Si votre climatiseur fixe ne souffle plus d'air froid ou que le débit d'air est trop faible, l'air ambiant ne sera pas correctement renouvelé dans votre foyer et la qualité de l'air sera dégradée. 
                    Si de la condensation s'installe dans votre intérieur, c'est que l'humidité de votre habitat n'est pas évacuée. 
                    Ne restez pas avec un air intérieur vicié. Les professionnels de l'entreprise Air Water System installent directement chez vous la climatisation adéquate qui va apporter de la fraîcheur à votre intérieur. 
                </p>
                <div className="columns is-centered">
                    <div className="column is-4 has-text-centered">
                        <img width="350" height="250" alt="Installation climatisation Moins" src={img2}></img>
                    </div>
                    <div className="column is-4 has-text-centered">
                        <img width="350" height="250" alt="Installation climatisation Moins" src={img3}></img>
                    </div>
                </div>
            </div>
        </section>
        <section className="section">
            <div className="container">
                <div className="columns is-vcentered">
                    <div className="column is-9">
                        <h3 className="title is-4">Pourquoi faire un entretien et une maintenance de climatisation à Mions ?</h3>
                        <p className="content">
                            La poussière et les saletés sur les filtres vont empêcher le bon passage de l'air. Des branches et des feuilles d'arbre peuvent également recouvrir la grille de l'unité extérieure et gêner l'aspiration et l'évacuation de l'air. 
                            Par conséquent, votre consommation énergétique va augmenter et le montant de votre facture d'électricité aussi. Vous pouvez confier la maintenance et l'entretien de vos appareils à notre entreprise pour retrouver un foyer agréable et confortable.
                        </p>
                    </div>
                    <div className="column is-3">
                        <img alt="Dépannage climatisation Mions" src={img4}></img>
                    </div>
                </div>
            </div>
        </section>
        <section className="section">
            <div className="container">
                <h3 className="title is-4">Des experts qualifiés pour un air intérieur sain</h3>
                <p className="content">
                    La manipulation des fluides frigorigènes requiert une formation spécifique. Nos artisans détiennent toutes les compétences essentielles pour prendre soin de votre climatiseur. Ils se chargeront du nettoyage des filtres, de la vérification du niveau de gaz ainsi que de l'étanchéité et s'assureront par l'application de produits spécifiques que la moisissure et les champignons ne réapparaissent pas. Le professionnel est le seul expert à posséder les connaissances pour prendre en charge la maintenance et l'entretien de votre installation de climatisation. Après avoir effectué la maintenance complète de votre système de climatisation, nos artisans testent le fonctionnement intégral de votre climatiseur : vérification du débit d'air, de la puissance frigorifique et du niveau de refroidissement de votre clim, qualité de l'air chaud sur les systèmes réversibles...
                </p>
            </div>
        </section>
        <section className="section">
            <div className="container">
                <h3 className="title is-4">Quels conseils prodigués par des professionnels de la climatisation à Mions ?</h3>
                <p className="content">
                    L'expert analyse votre projet dans les moindres détails et vous explique quel climatiseur convient le mieux à votre foyer : climatiseur mobile, réversible, mural, chauffage thermodynamique fonctionnant avec une pompe à chaleur... Un climatiseur opérationnel va optimiser la qualité de l'air dans votre habitat. Les chaleurs étouffantes dus aux étés caniculaires et les nuits d'insomnie ne seront bientôt plus qu'un mauvais souvenir. Le bien-être thermique sera appréciable chez vous aussi bien en hiver qu'en été. Pour obtenir une température optimale chez vous, les professionnels d’Air Water System se tiennent à votre disposition. Contactez-nous pour obtenir un devis détaillé.
                </p>
            </div>
        </section>
        <section className="section">
            <div className="container">
                <div className="content">
                    <figure className="image">
                        <img alt="© Air Water System" src={img5}></img>
                    </figure>
                    <p>
                        Faites appel à nous ! Nous nous déplacerons chez vous dans le Rhône pour faire une expertise concernant le dépannage, l'entretien, l'optimisation ou l'installation de votre climatisation.
                        <br></br>
                        Pour plus de renseignements concernant l'installation ou la réparation de votre chauffage, votre plomberie ou votre climatisation ou pour toute demande de devis, n’hésitez pas à contacter notre entreprise Air Water System à Mions.
                    </p>
                </div>
            </div>
        </section>
    </Layout>
)

export default ClimatisationPage;
